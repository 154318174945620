<template>
  <div class="mask">
    <!-- <div class="topContainer">
      <div class="top">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="设备类型">
            <el-select v-model="form.deviceType" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="离散率区间">
            <el-select v-model="form.divergenceRange" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="统计时间">
            <el-date-picker
              v-model="form.date"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">搜索</el-button>
            <el-button type="primary" @click="resert">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div> -->
    <div>
      <div class="title">组串离散率统计</div>
      <div>
        <div id="echarts" class="charts"></div>
      </div>
    </div>
    <div class="title">组串离散率列表</div>
    <div class="my-table">
      <el-table :data="tableData" height="46vh" max-height="48vh" class="btable">
        <el-table-column
          prop="deviceTypeName"
          label="设备类型"
          align="center"
          fixed="left"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="deviceName"
          label="设备名称"
          align="center"
          fixed="left"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="dispersionRate"
          label="离散率"
          align="center"
          fixed="left"
        ></el-table-column>
        <el-table-column
          align="left"
        >
          <template slot="header" slot-scope="scope">
            <div class="selfheader">
              <div>组串平均电压(V)/电流(A)</div>
              <div class="tagbg1"></div>
              <div>平均电流&lt;0.3A</div>
              <div class="tagbg2"></div>
              <div>平均电流最大偏差&gt;1.0A</div>
              <div class="tagbg3"></div>
              <div>0.5A&lt;平均电流最大偏差&lt;1.0A</div>
            </div>
          </template>
          <el-table-column
            v-for="i in 20"
            :key="i"
            prop="'deviceBranchConfigInfosList"
            :label="'组串'+i"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <span>{{setStrand(scope.row.deviceBranchConfigInfosList,i)}}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import discreteOptions from "../options/discreteOptions";
export default {
  data() {
    return {
      powerStationList: [],
      form: {
        deviceType: "",
        divergenceRange: "",
        date: "",
      },
      options: [
        {
          label: '选项一',
          value: 1,
        },
        {
          label: '选项二',
          value: 2,
        }
      ],
      value: "",
      total: 0,
      queryInfo: {
        pageSize: 10,
        currPage: 1,
        condition: {},
      },
      loadding: false,
      tableData:[
        {
          name: '1',
          date: '2',
          address: '3',
          value: 'iii'
        },
        {
          name: '1',
          date: '2',
          address: '3',
          value: 'iii'
        },
        {
          name: '1',
          date: '2',
          address: '3',
          value: 'iii'
        }
      ],
    };
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    search() {
      // this.getPowerStation();irradiation/list
    },
    createEcharts() {
      let myChart = echarts.init(document.getElementById("echarts"));
      const _option = discreteOptions.discreteOptions();
      myChart.setOption(_option);
    },
    // 获取详情
    async getDetail() {
      this.loading = true
      let res = await this.$http.post("powerStationDeviceSummary/selectPvByStation", {
        powerStationCode: localStorage.getItem("powerStationCode"),
      });
      this.createEcharts();
      if (res.data.code == 200) {
        this.tableData = res.data.data;
        this.loadding = false
      } else {
        this.$message.error(res.data.message);
        this.loadding = false
      }
    },
    setStrand(list,i){
      if(list){
        const index = list.findIndex(item=>item.pointName == 'PV'+i)
        const _v = list[index]?.valueV
        const _a = list[index]?.valueA
        const res = (_v && _a)?(_v + '/' + _a):''
        return res
      }else{
        return ''
      }
    },
    resert() {
      this.form = {
        powerStationName: "",
        deptName: "",
      };
      // this.getPowerStation();
    },
  },
};
</script>

<style lang="scss" scoped>
.mask {
  background-color: #223f6c;
  height: 100%;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
}

.title {
  color: #fff;
  font-size: 16px;

  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 16px;
    background-color: #0796db;
    margin-right: 10px;
  }
}

.top {
  padding-top: 20px;
  box-sizing: border-box;
  // text-align: end;
}

::v-deep .el-form-item__label {
  color: #fff;
}
::v-deep .el-input__inner {
  background-color: #223f6c !important;
  color: #fff !important;
}

.charts {
  width: 90%;
  height: 320px;
}

.my-table {
  border-bottom: 1px solid #1c4b76;
  margin-top: 15px;
}
.my-table ::v-deep .el-table td.el-table__cell,
.my-table ::v-deep .el-table th.el-table__cell.is-leaf {
  background-color: #21527e !important;
  border: 1px solid #1c4b76;
  color: #fff !important;
}

::v-deep .el-table__empty-block {
  background-color: #2d5981;
  border: 1px solid #5f809d;
}
::v-deep .el-table__body-wrapper {
  border-bottom: none !important;
}
::v-deep .el-table::before {
  z-index: 0 !important;
}
/deep/ .el-table{
    margin-top: 0px;
}
/deep/ .el-table--border, .el-table--group {
    border: 1px solid rgba(151, 179, 203, 0.55);
}
    /deep/ .el-table--border th{
        background-color: #21527e;

     border: 1px solid #737c85;
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    }

    /deep/ .el-table th,/deep/.el-table thead.is-group th{
        background-color: #2d5981;
     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    }
    /deep/ .el-table td {
        background-color: #2d5981;
     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    }
  /deep/ .el-table__body-wrapper{
    background: #2d5981;
  }
  /deep/ .el-pagination button:disabled{
    background: rgba(255, 255, 255, 0);
  }
  /deep/ .el-pager li.active {
    color: #f2f2f2;
    background-color: #00b2e6;
    cursor: default;
}

  /deep/ .el-pagination__total{
    color: #fff;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 12px;
  }
  /deep/.el-pager,/deep/.btn-prev,/deep/.btn-next{
    background:rgba(255, 255, 255, 0);
    color: #fff;
    .number{
        background:rgba(255, 255, 255, 0);
    }
  }
 /deep/ .el-pagination__jump{
    color: #fff;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 15px;
  }
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #223f6c !important;
}
/deep/ .el-table__body tr.hover-row>td{
  background-color: #223f6c !important;
}
/deep/.el-table__fixed-right::before, /deep/.el-table__fixed::before{
  display: none;
}
.btable{
  background: #2d5981;
  /deep/.el-table__body-wrapper{
    &::-webkit-scrollbar {
      display: block;
      width: 4px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #02a7f0;
    }
  }
}
.selfheader{
  display: flex;
  align-items: center;
}
.tagbg1{
  display: inline-block;
  width: 34px;
  height: 18px;
  border-radius: 3px;
  margin: 0 12px 0 20px;
  background-color: #ec407a;
}
.tagbg2{
  display: inline-block;
  width: 34px;
  height: 18px;
  border-radius: 3px;
  margin: 0 12px 0 20px;
  background-color: #ba68c8;
}
.tagbg3{
  display: inline-block;
  width: 34px;
  height: 18px;
  border-radius: 3px;
  margin: 0 12px 0 20px;
  background-color: #e1bee7;
}
</style>

