export default {
  // 组串离散率统计
  discreteOptions (edata) {
    // const timelist = edata.map(item=>{
    //   return item.date.substring(11,16)
    // })
    // const efficiencyData = edata.map(item=>{
    //   return item.efficiency
    // })
    return {
      grid: {
        left: "6%",
        right: "6%",
        bottom: "3%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
      //   formatter: function (data) {
      //     let html = ``;
      //     html = `
      // <div>${data[0].marker} ${data[0].seriesName}: ${data[0].value}MV</div>
      // <div>${data[1].marker} ${data[1].seriesName}: ${data[1].value}W/m²</div>
      // `;
      //     return html;
      //   },
      },
      legend: {
        show: true,
        // x: "center",
        // y: "10",
        textStyle: {
          color: "#97b3cb",
        },
        lineStyle: {
          color: "#02a7f0",
        },
        data: ["发电功率", "辐照强度"],
      },
      xAxis: {
          type: "category",
          data: [
            "20%以上",
            "10%~20%",
            "5%~10%",
            "0~5%",
            "数据缺失未分析",
          ],
          // boundaryGap: false,
          // axisTick: {
          //   alignWithLabel: true,
          // },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#97b3cb", // x轴颜色
            },
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
              width: 1,
            },
          },
      },
      yAxis: [
        {
          type: "value",
          name: "台",
          min: 0,
          axisLine: {
            lineStyle: {
              color: "#fff",
              width: 1,
            },
          },
          axisLabel: {
            formatter: "{value}",
            // y轴数据颜色
            textStyle: {
              color: "#fff",
            },
          },
          splitLine: {
            show: true,
          },
        },
        {
          type: "value",
          name: "%",
          min: 0,
          max: 100,
          axisLabel: {
            formatter: "{value}",
            textStyle: {
              color: "#97b3cb", //y轴的字体颜色
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#97b3cb", // 发电总量颜色
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
          },
        },
      ],
      series: [
        {
          name: "台数",
          type: "bar",
          barWidth: 24,
          data: [3, 7, 9, 4, 10],
          itemStyle: {
            color: "#aaaaaa",
          },
          label: {
            show: false, // 开启标签显示
            position: "top", // 标签的位置，可以根据需要调整
            textStyle: {
              color: "#aaaaaa",
            },
          },
        },
        {
          type: "line",
          name: "平均值",
          data: ["5", "10", "33", "70"],
          yAxisIndex: 1,
          zlevel: 3,
          color: "#02a7f0",
        }
      ],
    };
  }
}