import { render, staticRenderFns } from "./Discrete.vue?vue&type=template&id=0fa1a666&scoped=true"
import script from "./Discrete.vue?vue&type=script&lang=js"
export * from "./Discrete.vue?vue&type=script&lang=js"
import style0 from "./Discrete.vue?vue&type=style&index=0&id=0fa1a666&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa1a666",
  null
  
)

component.options.__file = "Discrete.vue"
export default component.exports